import { render, staticRenderFns } from "./Upload.vue?vue&type=template&id=48b9a2e6&scoped=true&"
import script from "../assets/js/upload.js?vue&type=script&lang=ts&"
export * from "../assets/js/upload.js?vue&type=script&lang=ts&"
import style0 from "../assets/styles/main.less?vue&type=style&index=0&id=48b9a2e6&prod&lang=less&scoped=true&"
import style1 from "../plugins/css/tui-image-editor.css?vue&type=style&index=1&prod&rel=stylesheet&lang=css&"
import style2 from "../assets/styles/upload.less?vue&type=style&index=2&id=48b9a2e6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b9a2e6",
  null
  
)

export default component.exports